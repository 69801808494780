.heading-container {
  position: relative;
  margin-top: 30px;
  margin-bottom: 25px;
  text-align: center;
  overflow: auto;
  z-index: 1;
  /* offset-x | offset-y | blur-radius | spread-radius | color */
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.therapy-img {
  max-width: 60vw;
  float: center;
  border-radius: 2px;
  filter: brightness(60%);
  z-index: 0;
  object-fit: cover;
}

@media only screen and (max-width: 1026px) {
  .therapy-img {
    max-width: 94vw;
  }
}

.heading-text {
  position: absolute;
  top: 49%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  text-shadow: 2px 2px 18px #000;
  width: 80%;
  max-width: 45vw;
  font-size: xx-large;
}
