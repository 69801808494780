@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap');

.App-content {
  text-align: center;
  width: 100vw;
  min-height: 100vh;
  /* background-color: #576675; */ /* Now defined directly in App.tsx to that the common colors.ts can be referenced */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: 'Verdana-light', sans-serif;
  background-color: #485c70;
}

.App-background-gradient {
  /* Define background gradient with various versions for maximum compatability */
  /* background: hsla(210, 22%, 36%, 1);

background: linear-gradient(271deg, hsla(210, 22%, 36%, 1) 55%, hsla(341, 39%, 14%, 1) 100%);

background: -moz-linear-gradient(271deg, hsla(210, 22%, 36%, 1) 55%, hsla(341, 39%, 14%, 1) 100%);

background: -webkit-linear-gradient(271deg, hsla(210, 22%, 36%, 1) 55%, hsla(341, 39%, 14%, 1) 100%);

filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#485C70", endColorstr="#32161F", GradientType=1 ); */
  /* background: transparent; */
  background-image: url('./assets/sunset-gradient2-cropped.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: calc(131 / 200 * 100%) calc(66 / 200 * 100%);
  backdrop-filter: blur(50%);
  height: 100%;
  max-width: 100vw;
  max-height: 100vh;
  position: absolute;
  z-index: 0;
  height: 100vh;
  width: 100vw;
}

.header-background {
  background: transparent;
  /* background: #485c70;
  background: -moz-linear-gradient(90deg, rgba(72,92,112,1) 0%, rgba(98,122,145,1) 50%, rgba(72,92,112,1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(72,92,112,1) 0%, rgba(98,122,145,1) 50%, rgba(72,92,112,1) 100%);
  background: linear-gradient(90deg, rgba(72,92,112,1) 0%, rgba(98,122,145,1) 50%, rgba(72,92,112,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#485c70",endColorstr="#485c70",GradientType=1); */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}

p {
  font-size: 15px;
  text-align: left;
}
