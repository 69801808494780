.leaflet-container {
  height: 70vh;
  width: 80vw;
  max-width: 80vw;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 1px;
}

@media only screen and (max-width: 1026px) {
  .leaflet-container {
      height: 480px;
      width: 98vw;
      margin-left: auto;
      margin-right: auto;
  }
}
